import { getRandomInt, checkPasswords } from '@/modules/functions'

/**
 * Constructeur de mot de passe fort
 *
 * l'attribut result pour récupèrer le mot de passe
 *
 * new PasswordGenerator().result
 */
export default class PasswordGenerator {
  constructor(size = 8, specialChar = '.&@') {
    this.result = null
    this.specialChar = specialChar
    this.build(size)
  }

  build(size) {
    const password = this.randomStrGenerator(size)
    if (!checkPasswords(password, password)) return this.build(size + 1)

    this.result = password
  }

  randomStrGenerator(size) {
    const Obj = {
      passwdGen: '',
      passwdChar:
        'azertyuiopqsdfghjklmwxcvbnAZERTYUIOPQSDFGHJKLMWXCVBN0123456789',
      indexSpecialChar: getRandomInt(size),
    }

    for (let idx = 0; idx < parseInt(size); idx++) {
      Obj.passwdGen +=
        idx === Obj.indexSpecialChar
          ? this.specialChar[getRandomInt(this.specialChar.length)]
          : Obj.passwdChar[getRandomInt(Obj.passwdChar.length)]
    }

    return Obj.passwdGen
  }
}
