import { PasswordGenerator } from '../modules/constructors'
import {
  getRandomInt,
  getRandomStr,
  truncateStr,
  canWebpUse,
  checkPasswords,
  checkEmail,
  handleSmooth,
  Lowercase,
  Uppercase,
  Capitalize,
  getCsrfToken,
  setCsrfToken,
} from '@/modules/functions'

import { mapState } from 'vuex'

export default {
  data() {
    return {
      isPassed: {
        username: false,
        async_username: false,
        password: false,
        email: false,
        captcha: false,
        termsanduse: false,
      },
    }
  },
  computed: {
    ...mapState(['store_check_form']),
  },
  methods: {
    // Check Termsanduse
    onTermsAndUse(state) {
      this.isPassed.termsanduse = state
    },
    // Check Termsanduse
    //
    // Check Username
    onStateUsername(username) {
      if (!username || username.length === 0 || this.isPassed.async_username) {
        this.isPassed.username = null
        return null
      }
      const state = this.store_check_form.usernameAvailable
      this.isPassed.username = state
      return state
    },
    onFocusUsername() {
      this.isPassed.username = null
    },
    onBlurUsername(username) {
      this.isPassed.async_username = false
      if (!username || username.length === 0) {
        this.isPassed.username = null
        return null
      }
      this.$store.dispatch('store_check_form/usernameAvailable', username)
      const state = this.store_check_form.usernameAvailable
      this.isPassed.username = state
      return state
    },
    onInputUsername() {
      this.isPassed.async_username = true
    },
    // Check Username
    //
    // Check Password
    onStatePassword() {
      const state = checkPasswords(this.form.password1, this.form.password2)
      this.isPassed.password = state
      return state
    },
    // Check Password
    //
    // Check Courriel
    onStateCourriel() {
      const mail1 = this.form.email1 || null
      if (!mail1) {
        this.isPassed.email = false
        return null
      } else {
        const state = checkEmail(mail1)
        this.isPassed.email = state
        return state
      }
    },
    onStateCourriels() {
      const mail1 = this.form.email1 || null
      const mail2 = this.form.email2 || null

      if (mail1 !== mail2) {
        this.isPassed.email = false
        return false
      } else {
        const state = this.onStateCourriel()
        this.isPassed.email = state
        return state
      }
    },
    // Check Courriel
    //
    // Misc ...
    passwordGenerator(size = 10, specialChars = '@') {
      const passwordGenerator = new PasswordGenerator(size, specialChars)

      this.form.password1 = passwordGenerator.result
      this.form.password2 = passwordGenerator.result
    },
    upperCase(Str) {
      return Uppercase(Str)
    },
    lowerCase(Str) {
      return Lowercase(Str)
    },
    capitalize(Str) {
      return Capitalize(Str)
    },
  },
}
