<template>
  <b-container :class="mainClass" :style="mainStyle" fluid="lg">
    <div class="text-center my-0 py-0">
      <b-icon icon="person-badge-fill" font-scale="10" variant="secondary" />
      <h1 class="text-center mt-1 mb-3">Inscription</h1>
    </div>

    <b-form @submit="onSubmit">
      <!-- Username -->
      <FormRowHalfCol>
        <template v-slot:left>
          <InputPrependIcon icon="person-fill" />
          <b-form-input
            type="text"
            placeholder="Utilisateur"
            v-model="form.username_reg"
            :state="onStateUsername(form.username_reg)"
            @focus="onFocusUsername()"
            @blur="onBlurUsername(form.username_reg)"
            @input="onInputUsername()"
            :formatter="lowerCase"
            maxlength="30"
            required
          />

          <FormInvalidFeedback
            label="Nom d'utilisateur indisponible"
            :state="onStateUsername(form.username_reg)"
          />
        </template>
      </FormRowHalfCol>

      <!-- Password -->
      <FormRowDualCol>
        <template v-slot:left>
          <InputPrependIcon icon="key-fill" />
          <b-form-input
            type="text"
            placeholder="Mot de passe"
            v-model="form.password1"
            :state="onStatePassword()"
            maxlength="50"
            required
          />
          <b-input-group-append>
            <b-button
              variant="info"
              @click="passwordGenerator(8)"
              class="bounce_effect"
            >
              <b-icon icon="tools" />
            </b-button>
          </b-input-group-append>
        </template>
        <template v-slot:right>
          <InputPrependIcon icon="key-fill" />
          <b-form-input
            type="text"
            placeholder="Confirmer mot de passe"
            v-model="form.password2"
            :state="onStatePassword()"
            maxlength="50"
            required
          />

          <FormInvalidFeedback
            class="bounce_effect"
            label="Mot de passe trop faible ou ne correspond pas"
            :state="onStatePassword()"
          />
        </template>
      </FormRowDualCol>

      <!-- Courriel -->
      <FormRowDualCol>
        <template v-slot:left>
          <InputPrependIcon icon="mailbox" />
          <b-form-input
            type="email"
            placeholder="Courriel"
            v-model="form.email1"
            :state="onStateCourriels()"
            :formatter="lowerCase"
            maxlength="50"
            required
          />
        </template>
        <template v-slot:right>
          <InputPrependIcon icon="mailbox" />
          <b-form-input
            type="email"
            placeholder="Confirmer adresse de Courriel"
            v-model="form.email2"
            :state="onStateCourriels()"
            :formatter="lowerCase"
            maxlength="50"
            required
          />
          <FormInvalidFeedback
            class="bounce_effect"
            label="L'adresse de courriel est invalide ou ne correspond pas"
            :state="onStateCourriels()"
          />
        </template>
      </FormRowDualCol>

      <!-- lastName -->
      <FormRowDualCol>
        <template v-slot:left>
          <InputPrependIcon icon="person-badge-fill" />
          <b-form-input
            type="text"
            placeholder="Prénom"
            v-model="form.firstname"
            :formatter="capitalize"
            maxlength="30"
          />
        </template>
        <template v-slot:right>
          <InputPrependIcon icon="person-badge-fill" />
          <b-form-input
            type="text"
            placeholder="Nom"
            v-model="form.lastname"
            :formatter="upperCase"
            maxlength="30"
          />
        </template>
      </FormRowDualCol>

      <!-- TermsandUse -->
      <FormRowHalfCol>
        <template v-slot:left>
          <FormTermsAndUse
            v-on:terms-and-use="onTermsAndUse"
            label="J'accepte les conditions d'utilisations"
            class="my-2 slide_in_from_left"
          />
        </template>
      </FormRowHalfCol>

      <!-- Create -->

      <FormRowHalfCol>
        <template v-slot:left>
          <b-button
            variant="info"
            class="text-center slide_in_from_right"
            size="lg"
            type="submit"
            large
            block
            :disabled="!isPassedCheck"
            >{{ capitalize('création du compte ...') }}
          </b-button>
        </template>
      </FormRowHalfCol>

      <!-- <div>{{ isPassed }}</div> -->
    </b-form>

    <hr class="bg-info hr-50" />

    <!-- Return to login -->
    <FormRowHalfCol>
      <template v-slot:left>
        <b-link tag="a" to="login" class="text-info slide_in_from_left"
          >Vous avez déja un compte ?</b-link
        >
      </template>
    </FormRowHalfCol>
  </b-container>
</template>

<script>
import forms from '../mixins/forms'

export default {
  components: {
    FormRowDualCol: () => import('../components/FormRowDualCol'),
    FormRowHalfCol: () => import('../components/FormRowHalfCol'),
    InputPrependIcon: () => import('../components/InputPrependIcon'),
    FormTermsAndUse: () => import('../components/FormTermsAndUse'),
    FormInvalidFeedback: () => import('../components/FormInvalidFeedback'),
  },
  mixins: [forms],
  data() {
    return {
      mainClass: ['mt-5'],
      mainStyle: ['max-width: 800px'],
      form: {
        username_reg: '',
        password1: '',
        password2: '',
        email1: '',
        email2: '',
        firstname: '',
        lastname: '',
        captcha: '',
      },
    }
  },
  computed: {
    isPassedCheck() {
      if (!this.isPassed.termsanduse) return false
      else if (!this.isPassed.username) return false
      else if (!this.isPassed.password) return false
      else if (!this.isPassed.email) return false
      else return true
    },
  },
  mounted() {
    // this.$store.dispatch('store_csrf/get')
    this.$store.dispatch('store_csrf/test')
  },
  beforeDestroy() {
    this.onDestroy()
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.$store.dispatch('store_check_form/overlayState', true)

      this.$root.timeout = setTimeout(() => {
        this.$store.dispatch('store_check_form/overlayState', false)
        this.$store.dispatch('store_auth/register', this.form)
        this.$store.dispatch('store_debug/set', this.form)
      }, 2000)
    },
    onDestroy() {
      clearTimeout(this.$root.timeout)
      this.$store.dispatch('store_csrf/delete')
      this.form = null
      this.$store.dispatch('store_debug/set')
    },
  },
}
</script>
